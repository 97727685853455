/**
 * Created by Kotkin on 16.06.2017.
 * edited 10.01.2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18nHoc } from 'helpers/i18n';
import { subscribe, unsubscribe } from 'helpers/global-events';
import { UserProp } from 'auth';
import * as actions from 'auth/redux';
import LoginFormContainer from 'auth/login-form-container';
import Modal, { Header, Content } from 'components/modal';

import translates from './login-i18n.json';

@connect(state => ({
  auth: state.auth,
}), { ...actions })
@I18nHoc(translates)
export default class LoginModal extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      action: PropTypes.string,
      data: UserProp
    }),
    onlySocialAuth: PropTypes.bool
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordRepair = this.passwordRepair.bind(this);
    this.close = this.close.bind(this);

    this.state = {
      email: null,
      opened: false,
      clientRendered: false,
      header: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.clientRendered) {
      const curAuth = props.auth || {};

      if (
        !state.opened &&
        props.auth.action === actions.DO_LOGIN &&
        !curAuth.data.authenticated
      ) {
        return {
          opened: true,
        }
      } else if (
        state.opened && state.page !== 'passrepair'
      ) {
        return {
          opened: false,
        }
      }
    }
    return null;
  }

  componentDidMount() {
    subscribe('callPasswordRepair', this.passwordRepair);
    if (__CLIENT__) this.setState({ clientRendered: true }); // eslint-disable-line
  }

  componentWillUnmount() {
    unsubscribe('callPasswordRepair', this.passwordRepair);
  }

  passwordRepair(data) {
    this.setState({
      email: data.email,
      page: 'passrepair',
      header: this.i18n('passwordRecovery')
    });
  }

  close() {
   this.setState({
      page: 'login',
      header: null
    });
  }

  handleSubmit() {
    this.loginFormRef.submitForm();
  }

  changeHeader =(h)=> {
    this.setState({ header: h || this.i18n('signIn') });
  }

  render() {
    const { clientRendered, opened, email, page } = this.state;

    if (!clientRendered) return null;

    const header = this.state.header || this.i18n('signIn');
    const { onlySocialAuth } = this.props;

    return (
      <Modal
        className={`modalAuth${onlySocialAuth ? ' modalAuth_onlySocial' : ''}`}
        size="big"
        opened={opened}
        onCloseRequired={this.close}
      >
        <Header closeButton children={header} />
        <Content adaptiveHeight>
          <LoginFormContainer
            email={email}
            page={page}
            wRef={ref => this.loginFormRef = ref}
            onAuthenticated={this.close}
            closeModal={this.close}
            changeHeader={this.changeHeader}
            onlySocialAuth={onlySocialAuth}
          />
        </Content>
      </Modal>
    );
  }
}
